import React from 'react';
import cn from 'classnames';
import classes from './OffersTable.module.css';

export const OffersTable = ({ children }) => {
  return (
    <div className={classes.wrapper}>
      <table className={cn(classes.table)}>
        <tbody className={classes.tbody}>{children}</tbody>
      </table>
    </div>
  );
};
