import { Button, useIntlScope } from '@lemonone/components';
import { getDurationParts } from '@lemonone/helpers';
import cn from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import classes from './OffersTableItem.module.css';
import { OffersTableItemStatus } from './OffersTableItemStatus';
import { OfferStatus } from './OfferStatus';

export const OffersTableItem = ({
  uuid,
  dateTime,
  address,
  travelDistance,
  travelDuration,
  shootDuration,
  cost,
  currency,
  status,
  timeZoneId
}) => {
  const i18n = useIntl();
  const intl = useIntlScope('@lemonone/intl/text:');
  const app = useIntlScope('@lemonone/acceptphotoshoot/text:');
  const component = useIntlScope('@lemonone/components/Offer:');
  return (
    <tr className={cn(classes.itemRow, { [classes.acceptedOffer]: status === OfferStatus.ACCEPTED })}>
      <td className={cn(classes.cell)}>
        <span className={cn(classes.fieldDateTime)}>
          {i18n.formatDate(dateTime, { format: 'dateTime', timeZone: timeZoneId })}
        </span>
        <span className={cn(classes.fieldDuration)}>{intl('estimatedDuration', getDurationParts(shootDuration))}</span>
      </td>
      <td className={cn(classes.cell)}>
        <span className={cn(classes.fieldAddress)}>{address}</span>
        <span className={cn(classes.fieldUnderAddress)}>
          {component('distance', {
            distance: i18n.formatNumber(travelDistance),
            duration: i18n.formatNumber(travelDuration)
          })}
        </span>
      </td>
      <td className={cn(classes.cell, classes.cellPrice)}>
        <span className={cn(classes.fieldCost)}>{i18n.formatNumber(cost / 100, { format: 'currency', currency })}</span>
      </td>
      <td className={cn(classes.cell)}>
        {status === 'AVAILABLE' && (
          <Link to={`/?offerid=${uuid}`}>
            <Button size="xs">{app('check_offer')}</Button>
          </Link>
        )}
        {status !== 'AVAILABLE' && <OffersTableItemStatus status={status} />}
      </td>
    </tr>
  );
};
