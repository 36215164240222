import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import classes from './index.module.css';
import demo from './demo.mp4';
import logo from '../AddToHomeScreenButton/logo.svg';

const AddToHomeScreenSafariDemo = ({ onClick }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    document.body.classList.add(classes.overlay);
    document.documentElement.classList.add(classes.overlay);
    return () => {
      document.documentElement.classList.remove(classes.overlay);
      document.body.classList.remove(classes.overlay);
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <button className={classes.closeBtn} onClick={onClick}>
        &#10005;
      </button>
      <video
        autoPlay
        onLoadedData={() => setLoaded(true)}
        src={demo}
        className={cn(classes.demo, { [classes.demoLoaded]: loaded })}
        playsInline
        loop
      ></video>
      <div className={classes.caption}>
        <img src={logo} alt="logo" className={classes.logo} />
        <span className={classes.text}>
          Follow these steps in order to add
          <br />
          the LemonOne app to your homescreen
        </span>
      </div>
    </div>
  );
};

export default AddToHomeScreenSafariDemo;
