/* eslint-disable quotes */

const intl = require('@lemonone/intl/lib/de');
const components = require('@lemonone/components/lib/i18n/de');
const en = require('./en');

const unauthorized =
  '⚠️ Deine Sitzung ist abgelaufen.\n\nDu kannst dich erneut anmelden, indem du auf das Feld "Möchtest du mehr verdienen?" klickst, dieses findest du in den E-Mails mit den Shootinganfragen.\n\nWenn das Problem weiterhin besteht, wende dich bitte an unser Support-Team.';

module.exports = Object.assign({}, en, intl, components, {
  '@lemonone/acceptphotoshoot/pages:photoshoot': 'Foto-Shooting',
  '@lemonone/acceptphotoshoot/text:accept': 'Foto-Shooting akzeptieren',
  '@lemonone/acceptphotoshoot/text:terms':
    'Durch den Klick auf "Foto-Shooting akzeptieren" erklärst du dich mit unseren <a>AGB</a> einverstanden.',
  '@lemonone/acceptphotoshoot/text:terms:href': 'https://www.lemonone.com/de/allgemeine-geschaeftsbedingungen',
  '@lemonone/acceptphotoshoot/text:availability':
    'Du bist zu der Zeit verfügbar und möchtest das Shooting annehmen, dann bestätige dies bitte mit der nachfolgenden Schaltfläche:',
  '@lemonone/acceptphotoshoot/text:booked': 'Sie wurden für dieses Foto-Shooting gebucht',
  '@lemonone/acceptphotoshoot/text:confirmed':
    'Der Termin wurde zu einem Kalendar hinzugefügt und eine Buchungsbestätigung wurde dir per E-Mail zugesendet',
  '@lemonone/acceptphotoshoot/text:unavailable': 'Leider wurde das ausgewählte Foto-Shooting bereits vergeben',
  '@lemonone/acceptphotoshoot/text:address': 'Adresse',
  '@lemonone/acceptphotoshoot/text:booked_date': 'Foto-Shooting Datum',
  '@lemonone/acceptphotoshoot/text:categories': 'Foto Kategorien',
  '@lemonone/acceptphotoshoot/text:actual_duration': 'Foto-Shooting Dauer',
  '@lemonone/acceptphotoshoot/text:earnings': '{earnings} {currency}',
  '@lemonone/acceptphotoshoot/text:travel_cost_included':
    'einschließlich Reisekosten nach § 2 des Vertrages, sonst §13 der AGB',
  '@lemonone/acceptphotoshoot/text:travel_cost_not_included':
    '+ Reisekostenausgleich nach § 2 des Vertrages, sonst §13 der AGB',
  '@lemonone/acceptphotoshoot/text:you_will_earn': 'Du verdienst dabei',
  '@lemonone/acceptphotoshoot/text:photoshoot_id': 'Foto-Shooting ID',
  '@lemonone/acceptphotoshoot/text:travel_estimation':
    '{travelDistance} km, {travelDuration} Minuten von Ihrer Adresse',
  '@lemonone/acceptphotoshoot/text:equipment': 'Benötigte Ausrüstung:',
  '@lemonone/acceptphotoshoot/text:detail_lens': 'Detail / Portrait-Objektiv',
  '@lemonone/acceptphotoshoot/text:wide_lens': 'Weitwinkel-Objektiv (16 - 20 mm Vollformat oder gleichwertiges)',
  '@lemonone/acceptphotoshoot/text:drone': 'Profesionelle Drohne',
  '@lemonone/acceptphotoshoot/text:drone_license': 'Drohnen Operator-Zertifikat/-Lizenz (erforderlich)',
  '@lemonone/acceptphotoshoot/text:tripod': 'Stativ mit einem Verlängerungsarm',
  '@lemonone/acceptphotoshoot/text:tilt_lens': 'Tilt-Shift-Objektiv',
  '@lemonone/acceptphotoshoot/text:360_camera': '360 Kamera',
  '@lemonone/acceptphotoshoot/text:today_date': 'Heute {timeNow, date, long}',
  '@lemonone/acceptphotoshoot/text:tomorrow_date': 'Morgen, {timeTomorrow, date, long}',
  '@lemonone/acceptphotoshoot/text:upcoming_days': 'In den kommenden Tagen',
  '@lemonone/acceptphotoshoot/text:all_offers': 'Alle Angebote',
  '@lemonone/acceptphotoshoot/text:check_shoots':
    'Weitere Foto-Shootings in deiner Nähe findest du <underline>hier</underline>',
  '@lemonone/acceptphotoshoot/text:earn_more': 'Möchten du mehr verdienen?',
  '@lemonone/acceptphotoshoot/text:no_offers':
    'Leider sind derzeit keine Foto-Shootings verfügbar. Versuche es später noch einmal!',
  '@lemonone/acceptphotoshoot/error:GraphQL error: jwt must be provided': unauthorized,
  '@lemonone/acceptphotoshoot/error:GraphQL error: jwt malformed': unauthorized,
  '@lemonone/acceptphotoshoot/error:GraphQL error: invalid signature': unauthorized,
  '@lemonone/acceptphotoshoot/error:GraphQL error: invalid token': unauthorized,
  '@lemonone/acceptphotoshoot/text:date': 'Datum',
  '@lemonone/acceptphotoshoot/text:address_and_type': 'Adresse und Typ',
  '@lemonone/acceptphotoshoot/text:price': 'Preis',
  '@lemonone/acceptphotoshoot/text:status': 'Status',
  '@lemonone/acceptphotoshoot/error:GraphQL error: No order or shoot given!':
    'Für die angegebene ID konnte kein Foto-Shooting gefunden werden.',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Photographer cannot shoot this customer!':
    'Der Fotograf kann für diesen Kunden keine Foto-Shooting ausführen!',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Photographer inactive!': 'Der Fotograf ist inaktiv!',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Photographer is missing tag!':
    'Du kannst dieses Foto-Shooting nicht annehmen. Die nötige Ausrüstung fehlt in deinem Profil.',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Photographer missing category!':
    'Du kannst dieses Foto-Shooting nicht annehmen. Die erforderliche Kategorie fehlt in deinem Profil.',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Photographer missing!':
    'Du kannst dieses Foto-Shooting nicht annehmen. In deinem Profil fehlt die Fotografen-ID.',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Product missing!':
    'Diesem Foto-Shooting wurde kein Produkt zugewiesen.',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Shoot lies in the past':
    'Die Foto-Shooting hat bereits stattgefunden.',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Variable \\"$targetPhotographerUuid\\" of required type \\"String!\\" was not provided.':
    'Du kannst dieses Foto-Shooting nicht annehmen. In deinem Profil fehlt die Fotografen-ID.',
  "@lemonone/acceptphotoshoot/error:GraphQL error: Variable 'orderUuid' has coerced Null value for NonNull type 'String!'":
    'Sie müssen die Foto-Shooting ID bereitzustellen',
  '@lemonone/acceptphotoshoot/text:install_app':
    'Tippen Sie hier, um die LemonOne App zu Ihrem Home-Bildschirm hinzufügen',
  '@lemonone/acceptphotoshoot/text:check_offer': 'Angebot ansehen',
  '@lemonone/acceptphotoshoot/text:offer_status/ACCEPTED': 'Akzeptiert',
  '@lemonone/acceptphotoshoot/text:offer_status/CANCELLED': 'Storniert',
  '@lemonone/acceptphotoshoot/text:offer_status/AVAILABLE': 'Verfügbar'
});
