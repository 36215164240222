export const mapOfferTableItem = ({ order, offer }) => {
  return {
    uuid: offer.uuid,
    dateTime: order.shoot.start,
    address: order.shoot.location.address,
    travelDistance: offer.travelDistance,
    travelDuration: offer.travelDuration,
    shootDuration: order.product.duration,
    cost: offer.cost,
    currency: offer.currency,
    status: offer.status,
    timeZoneId: order.shoot.location.timeZoneId
  };
};
