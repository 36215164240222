import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import AcceptPhotoShoot from '../components/AcceptPhotoShoot';

const AcceptPhotoShootPage = props => {
  const intl = useIntl();
  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: '@lemonone/acceptphotoshoot/pages:photoshoot' })}</title>
      </Helmet>
      <AcceptPhotoShoot {...props} />
    </>
  );
};

export default AcceptPhotoShootPage;
