import cn from 'classnames';
import { Row } from '@lemonone/components';
import React from 'react';

import classes from './Container.module.css';

export const Container = ({ children, gutters }) => {
  return <Row className={cn(classes.wrapper, gutters && classes.gutters)}>{children}</Row>;
};

export default Container;
