import ApolloClient, { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-boost';
import { LS_TOKEN_KEY } from './utils/constants';

import introspectionQueryResultData from '__generated__/fragmentMatcher.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

const GRAPHQL_ENDPOINT = process.env.REACT_APP_AWS_APPSYNC_FOTOQL_ENDPOINT;
const GRAPHQL_API_KEY = process.env.REACT_APP_AWS_APPSYNC_FOTOQL_API_KEY;

const client = new ApolloClient({
  headers: { 'x-api-key': GRAPHQL_API_KEY },
  uri: GRAPHQL_ENDPOINT,
  cache,
  request: operation => {
    const token = localStorage.getItem(LS_TOKEN_KEY);
    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`
        }
      });
    }
  }
});

export default client;
