import { Icon, Logo } from '@lemonone/components';
import cn from 'classnames';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Link, Route } from 'react-router-dom';

import Container from '../Container';
import classes from './NavBar.module.css';

const NavBar = () => {
  const [title, setTitle] = useState(document.title);
  return (
    <>
      <Helmet onChangeClientState={state => setTitle(state.title)} />
      <header className={cn(classes.wrapper)}>
        <Container gutters>
          <div className={classes.appBar}>
            <Route
              exact
              path="/"
              render={() => {
                return (
                  <Link to="/offers" className={classes.backButton}>
                    <Icon size="sm" variant="white" icon="back" />
                  </Link>
                );
              }}
            />
            <div className={classes.title}>{title}</div>
            <div className={classes.logo}>
              <Logo variant="secondary" size="md" />
            </div>
          </div>
        </Container>
      </header>
    </>
  );
};

export default NavBar;
