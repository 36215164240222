import Layout from 'components/Layout/Layout';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AcceptPhotoshootPage from './pages/AcceptPhotoshootPageContainer';
import Login from './pages/Login';
import OffersPage from './pages/OffersPage/OffersPage';

const App = () => {
  return (
    <Layout>
      <Switch>
        <Route path="/login/:token" component={Login} />
        <Route path="/offers" component={OffersPage} />
        <Route component={AcceptPhotoshootPage} />
      </Switch>
    </Layout>
  );
};

export default App;
