import { Footer, Row } from '@lemonone/components';
import Container from 'components/Container';
import NavBar from 'components/NavBar';
import React from 'react';
import classes from './Layout.module.css';
import cn from 'classnames';

const Layout = ({ children, icon }) => (
  <React.Fragment>
    <div className={cn(classes.block, classes.header)}>
      <NavBar icon={icon} />
    </div>
    <main className={cn(classes.block, classes.main)}>
      <Container gutters>
        {React.Children.map(children, child => {
          if (!child) return null;
          return <Row>{child}</Row>;
        })}
      </Container>
    </main>
    <div className={cn(classes.block, classes.footer)}>
      <Footer />
    </div>
  </React.Fragment>
);

export default Layout;
