import { useState, useCallback } from 'react';
import { track } from 'utils/analytics';

const KEY = 'a2hs';

const nextMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 1);
};

export const useAddToHomeScreen = () => {
  const [isVisible, setVisibility] = useState(new Date(localStorage.getItem(KEY)) <= Date.now());

  const onChange = useCallback(
    value => {
      const nextValue = !value;
      /* Advertise A2HS in a month */
      if (nextValue) {
        localStorage.setItem(KEY, nextMonth().toISOString());
        track('disabled', { category: 'addToHomeScreen' });
      } else {
        localStorage.removeItem(KEY);
      }
      setVisibility(!nextValue);
    },
    [setVisibility]
  );

  return [isVisible, onChange];
};
