import {
  Alert,
  Button,
  Icon,
  Link,
  Loading,
  MoreShootsBanner,
  Offer,
  Row,
  Spinner,
  Typography,
  useIntlScope
} from '@lemonone/components';
import { useGetOrderQuery, useGetShootOfferQuery, useSwitchShootPhotographerMutation } from '__generated__';
import React from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';

import { useSession } from '../../providers/SessionProvider';
import classes from './AcceptPhotoShoot.module.css';

export const Consent = () => {
  const app = useIntlScope('@lemonone/acceptphotoshoot/text:');
  return (
    <Typography>
      {app('terms', {
        a: chunks => (
          <Link href={app('terms:href')} target="_blank" rel="noreferrer">
            {chunks}
          </Link>
        )
      })}
    </Typography>
  );
};

const OfferPage = ({ data, query, mutation: [mutate, mutation] }) => {
  const app = useIntlScope('@lemonone/acceptphotoshoot/text:');
  const error = useIntlScope('@lemonone/acceptphotoshoot/error:');
  const page = useIntlScope('@lemonone/acceptphotoshoot/pages:');
  const { session } = useSession();

  if (query.loading) {
    return <Loading />;
  }

  if (query.error) {
    return (
      <Alert variant="error" data-testid="error">
        {error(query.error.message)}
      </Alert>
    );
  }

  if (!data) {
    return (
      <Alert variant="warning" data-testid="warning">
        {app('unavailable')}
      </Alert>
    );
  }

  return (
    <>
      <Row>
        <Typography size="lg" align="center" as="h1">
          📷 {page('photoshoot')}
        </Typography>
      </Row>
      {!mutation.data && (
        <Row>
          <Typography>{app('availability')}</Typography>
        </Row>
      )}
      <Row>
        <Offer {...data} />
      </Row>
      <Row>
        <Consent />
      </Row>
      {mutation.called && mutation.data && (
        <Row>
          <Alert variant="success" data-testid="success">
            <Typography>
              <Row as="span">
                <Typography weight="bold">{app('booked')}</Typography>
              </Row>
              <Row as="span">{app('confirmed')}</Row>
            </Typography>
          </Alert>
        </Row>
      )}
      {mutation.called && mutation.error && (
        <Row>
          <Alert variant="error" data-testid="error">
            {error(mutation.error.message)}
          </Alert>
        </Row>
      )}
      <Row>
        <Button
          data-testid="accept"
          size="md"
          fullWidth
          onClick={() => mutate().catch(() => {})}
          disabled={mutation.called && mutation.data}
          processing={mutation.loading}
        >
          {mutation.loading && <Spinner size="md" inverted />}
          {!mutation.loading && <Icon icon="accepted" variant="white" size="lg" />}
          <>{app('accept')}</>
        </Button>
      </Row>
      {session && (
        <Row>
          <RouterLink to="/offers" className={classes.banner}>
            <MoreShootsBanner href="/offers" />
          </RouterLink>
        </Row>
      )}
    </>
  );
};

const Page1 = ({ shootOfferUuid }) => {
  const query = useGetShootOfferQuery({
    variables: { shootOfferUuid },
    fetchPolicy: 'no-cache'
  });
  const data = query.data?.offer;
  const mutation = useSwitchShootPhotographerMutation({
    variables: {
      shootOfferUuid,
      shootUuid: data?.order.shoot.uuid,
      targetPhotographerUuid: data?.offer.photographerUuid
    }
  });
  return <OfferPage data={data} query={query} mutation={mutation} />;
};

const Page2 = ({ shootUuid, targetPhotographerUuid, sourcePhotographerUuid, skipCollisionCheck }) => {
  const query = useGetOrderQuery({
    variables: {
      shootUuid,
      sourcePhotographerUuid,
      targetPhotographerUuid,
      skipCollisionCheck
    },
    fetchPolicy: 'no-cache'
  });
  const order = query.data?.order;
  const mutation = useSwitchShootPhotographerMutation({
    variables: {
      shootUuid,
      targetPhotographerUuid,
      sourcePhotographerUuid,
      skipCollisionCheck
    }
  });
  return <OfferPage data={order ? { order } : undefined} query={query} mutation={mutation} />;
};

const AcceptPhotoShoot = ({
  shootUuid,
  targetPhotographerUuid,
  sourcePhotographerUuid,
  shootOfferUuid,
  skipCollisionCheck
}) => {
  if (!(shootUuid || shootOfferUuid)) {
    return <Redirect to="/offers" />;
  }
  return (
    <>
      <div className={classes.wrapper}>
        {shootOfferUuid && (
          <Row>
            <Page1 shootOfferUuid={shootOfferUuid} />
          </Row>
        )}
        {shootUuid && (
          <Row>
            <Page2
              shootUuid={shootUuid}
              targetPhotographerUuid={targetPhotographerUuid}
              sourcePhotographerUuid={sourcePhotographerUuid}
              skipCollisionCheck={skipCollisionCheck}
            />
          </Row>
        )}
      </div>
    </>
  );
};

export default AcceptPhotoShoot;
