/* eslint-disable react/jsx-pascal-case */
import { IntlProvider as _IntlProvider, Loading } from '@lemonone/components';
import React, { useEffect } from 'react';

import { useGetProfileQuery } from '../__generated__';
import { useSession } from './SessionProvider';

export default function IntlProvider({ children }) {
  const query = useGetProfileQuery();
  const session = useSession();

  const locale = query.data?.getProfile?.language;

  useEffect(() => {
    if (session) {
      query.refetch().catch(() => {
        session.destroySession();
      });
    }
  }, [query, session]);

  if (query.loading) {
    return <Loading />;
  }

  return (
    <_IntlProvider locale={locale} load={locale => require(`../locales/${locale}`)}>
      {children}
    </_IntlProvider>
  );
}
