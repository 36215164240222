import { updateManifest } from 'manifest';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSession } from '../providers/SessionProvider';

const Login = () => {
  const { push } = useHistory();
  const { token } = useParams();
  const { createSession } = useSession();

  useEffect(() => {
    if (token) {
      createSession(token);
      updateManifest(token);
      push('/offers');
    }
  }, [token, push, createSession]);

  return null;
};

export default Login;
