import React, { useState } from 'react';
import { track } from 'utils/analytics';
import { useAddToHomeScreen } from '../../utils/useAddToHomeScreen';
import Container from '../Container';
import AddToHomeScreenButton from '../AddToHomeScreenButton';
import AddToHomeScreenSafariDemo from '../AddToHomeScreenSafariDemo';

const AddToHomeScreenSafari = () => {
  const [isVisible, setVisibility] = useAddToHomeScreen();
  const [isDemo, setDemoVisibility] = useState(false);

  const onInstall = e => {
    e.preventDefault();
    setDemoVisibility(!isDemo);
    track('install', { category: 'addToHomeScreen' });
  };

  const onClose = e => {
    e.preventDefault();
    e.stopPropagation(); /* do not trigger onInstall */
    setDemoVisibility(false);
    setVisibility(false);
  };

  const onDemo = e => {
    e.preventDefault();
    setDemoVisibility(!isDemo);
    track('demo', { category: 'addToHomeScreen' });
  };

  if (!isVisible) return null;

  return (
    <>
      {isDemo && <AddToHomeScreenSafariDemo onClick={onDemo} />}
      <Container>
        <AddToHomeScreenButton onInstall={onInstall} onClose={onClose}>
          Tap here to see how to add
          <br /> LemonOne app to your home screen
        </AddToHomeScreenButton>
      </Container>
    </>
  );
};

export default AddToHomeScreenSafari;
