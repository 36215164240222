import React from 'react';
import cn from 'classnames';
import classes from './OffersTableItemStatus.module.css';
import { OfferStatus } from './OfferStatus';
import { useIntl } from 'react-intl';

export const OffersTableItemStatus = ({ status }) => {
  const intl = useIntl();
  return (
    <span
      className={cn(classes.wrapper, {
        [classes.statusAccepted]: status === OfferStatus.ACCEPTED,
        [classes.statusCancelled]: !status || status === OfferStatus.CANCELLED
      })}
    >
      <span className={cn(classes.text)}>
        {intl.formatMessage({ id: `@lemonone/acceptphotoshoot/text:offer_status/${status}` })}
      </span>
    </span>
  );
};
