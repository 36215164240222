/**
 * @module utils/analytics
 */
import { __DEV__ } from './constants';

/**
 * Google Analytics tracking function
 *
 * Loaded through Google Tag Manager
 * @see /publit/index.html
 *
 * @type {Function}
 */
const { ga } = window;

/**
 * Send an "event" to whatever analytics tools we have setup
 *
 * @example
 *    track('event',
 *      {
 *        category: '',
 *        action: '',
 *        label: '',
 *        value: 0
 *      }
 *    )
 *
 * @func track
 * @param options
 */
export function track(event, options = {}) {
  if (__DEV__) {
    console.info(`[analytics] ${event}`, options);
  }

  // Send to Google Analytics
  if (!__DEV__ && ga) {
    if (event === 'pageview') {
      ga('send', {
        hitType: 'pageview',
        page: options.label
      });
    } else {
      const eventCategory = options.category || 'user';
      const eventAction = event;
      const eventLabel = options.label;
      const eventValue = options.value;
      ga('send', {
        hitType: 'event',
        eventCategory,
        eventAction,
        eventLabel,
        eventValue
      });
    }
  }
}

/**
 * Send user related data to different tracking services
 *
 * @func identify
 * @param  {Object} options
 */
export function identify(options = {}) {
  const { sourcePhotographerUuid } = options;
  const userType = sourcePhotographerUuid ? 'internal' : 'photographer';

  if (__DEV__) {
    console.info('[analytics] set user data:', options);
  }

  // Send to Google Analytics
  if (!__DEV__ && ga) {
    userType && ga('set', 'dimension1', userType);
  }
}
