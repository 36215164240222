import React, { useEffect, useState } from 'react';
import { track } from 'utils/analytics';
import AddToHomeScreenButton from '../AddToHomeScreenButton';
import { useAddToHomeScreen } from '../../utils/useAddToHomeScreen';
import { useIntl } from 'react-intl';

const AddToHomeScreenChrome = () => {
  const intl = useIntl();
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [isVisible, setVisibility] = useAddToHomeScreen();

  useEffect(() => {
    const cb = e => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', cb);
    return () => window.removeEventListener('beforeinstallprompt', cb);
  }, []);

  const onInstall = async evt => {
    track('install', { category: 'addToHomeScreen' });
    evt.preventDefault();
    if (!promptInstall) return;
    track('prompted', { category: 'addToHomeScreen' });
    const { outcome } = await promptInstall.prompt();
    track(outcome, { category: 'addToHomeScreen' });
  };

  const onClose = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    setVisibility(false);
    track('closed', { category: 'addToHomeScreen' });
  };

  if (!isVisible) return null;
  if (!supportsPWA) return null;

  return (
    <AddToHomeScreenButton onInstall={onInstall} onClose={onClose}>
      {intl.formatMessage({ id: '@lemonone/acceptphotoshoot/text:install_app' })}
    </AddToHomeScreenButton>
  );
};

export default AddToHomeScreenChrome;
