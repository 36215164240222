import 'react-app-polyfill/stable';

import './manifest';

import { ThemeProvider } from '@lemonone/components';

import './styles/theme.css';
import './styles/base.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo';
import { Router } from 'react-router-dom';
import App from 'App';
import { createBrowserHistory } from 'history';
import { isChrome, isMobileSafari } from 'react-device-detect';
import { LS_TOKEN_KEY } from 'utils/constants';
import { updateManifest } from 'manifest';
import { __DEV__, GA_TRACKING_ID } from './utils/constants';
import { track } from './utils/analytics';
import AddToHomeScreenChrome from './components/AddToHomeScreenChrome';
import AddToHomeScreenSafari from './components/AddToHomeScreenSafari';
import IntlProvider from './providers/IntlProvider';
import { SessionProvider } from './providers/SessionProvider';
import { initLogger } from './utils/logger';

const history = createBrowserHistory();

initLogger();

if (!__DEV__ && GA_TRACKING_ID) {
  const getLabel = location => location.pathname + location.search;
  track('pageview', { label: getLabel(window.location) });
  history.listen(location => {
    track('pageview', { label: getLabel(location) });
  });
}

const { standalone } = window.navigator;

if (standalone) {
  /**
   * When app is pinned to the homescreen we inject
   * authorization in url (#)
   */
  const { hash } = window.location;
  if (hash) {
    const token = hash.substr(1);
    localStorage.setItem(LS_TOKEN_KEY, token);
    window.location.hash = '';
  }

  track('standalone', { category: 'addToHomeScreen' });
}

/**
 * Update manifest to include token if exists
 */
const token = localStorage.getItem(LS_TOKEN_KEY) || '';
updateManifest(token);

/**
 * Service worker is needed for A2HS
 */

if ('serviceWorker' in navigator) {
  (async () => {
    await navigator.serviceWorker.register('/sw.js');
  })();
}

ReactDOM.render(
  <Router history={history}>
    <ApolloProvider client={ApolloClient}>
      <SessionProvider>
        <ThemeProvider theme="greenish">
          <IntlProvider>
            {isChrome && !standalone && <AddToHomeScreenChrome />}
            {isMobileSafari && !standalone && <AddToHomeScreenSafari />}
            <App />
          </IntlProvider>
        </ThemeProvider>
      </SessionProvider>
    </ApolloProvider>
  </Router>,
  document.getElementById('root')
);
