const isToday = (dateTime, timeNow) => {
  const date = new Date(dateTime);
  const today = new Date(timeNow);
  return date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
};

const isTomorrow = (dateTime, timeNow) => {
  const date = new Date(dateTime);
  const start = new Date(timeNow);
  start.setDate(start.getDate() + 1);
  return date.setHours(0, 0, 0, 0) === start.setHours(0, 0, 0, 0);
};

/**
 * @return {{ today: Object[], tomorrow: Object[], others: Object[] }}
 */
export const groupOffers = (items, timeNow, cb) => {
  return [...items]
    .sort((a, b) => cb(a) - cb(b))
    .reduce(
      (res, item) => {
        const dateTime = cb(item);
        if (isToday(dateTime, timeNow)) {
          res.today.push(item);
          return res;
        }
        if (isTomorrow(dateTime, timeNow)) {
          res.tomorrow.push(item);
          return res;
        }
        res.others.push(item);
        return res;
      },
      {
        today: [],
        tomorrow: [],
        others: []
      }
    );
};
