import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Loading, Message, Row, Alert, Typography } from '@lemonone/components';
import { useGetPhotographerShootsQuery } from '__generated__';
import { OffersTable } from '../../components/OffersListing/OffersTable';
import { OffersTableGroup } from '../../components/OffersListing/OffersTableGroup';
import { OffersTableItem } from '../../components/OffersListing/OffersTableItem';
import { mapOfferTableItem } from './mapOfferTableItem';
import { groupOffers } from './groupOffers';

const renderItem = item => {
  const props = mapOfferTableItem(item);
  return <OffersTableItem key={props.uuid} {...props} />;
};

const calcGroupId = ({ order }) => new Date(order.shoot.start);

const OffersPage = () => {
  const intl = useIntl();
  const query = useGetPhotographerShootsQuery({ fetchPolicy: 'no-cache' });

  const header = (
    <Helmet>
      <title>{intl.formatMessage({ id: '@lemonone/acceptphotoshoot/text:all_offers' })}</title>
    </Helmet>
  );

  if (query.loading) {
    return (
      <>
        {header}
        <Loading />
      </>
    );
  }

  if (query.error) {
    return (
      <>
        {header}
        <Alert variant="warning">
          <Typography color="warning" whiteSpace="pre-line">
            {intl.formatMessage({
              id: `@lemonone/acceptphotoshoot/error:${query.error.message}`,
              defaultMessage: query.error.message
            })}
          </Typography>
        </Alert>
      </>
    );
  }

  const items = query.data.getPhotographerShoots;

  if (items.length === 0) {
    return (
      <>
        {header}
        <Message>{intl.formatMessage({ id: '@lemonone/acceptphotoshoot/text:no_offers' })}</Message>
      </>
    );
  }

  const timeNow = new Date();
  const timeTomorrow = new Date();
  timeTomorrow.setDate(timeNow.getDate() + 1);
  const offers = groupOffers(items, timeNow, calcGroupId);

  return (
    <>
      {header}
      <Row>
        <OffersTable>
          {offers.today.length > 0 && (
            <OffersTableGroup
              text={intl.formatMessage({ id: '@lemonone/acceptphotoshoot/text:today_date' }, { timeNow })}
            >
              {offers.today.map(renderItem)}
            </OffersTableGroup>
          )}
          {offers.tomorrow.length > 0 && (
            <OffersTableGroup
              text={intl.formatMessage({ id: '@lemonone/acceptphotoshoot/text:tomorrow_date' }, { timeTomorrow })}
            >
              {offers.tomorrow.map(renderItem)}
            </OffersTableGroup>
          )}
          {offers.others.length > 0 && (
            <OffersTableGroup
              text={intl.formatMessage({
                id: '@lemonone/acceptphotoshoot/text:upcoming_days'
              })}
            >
              {offers.others.map(renderItem)}
            </OffersTableGroup>
          )}
        </OffersTable>
      </Row>
    </>
  );
};

export default OffersPage;
