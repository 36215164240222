import React, { useEffect } from 'react';
import classes from './index.module.css';
import Container from '../Container';

import url from './logo.svg';

const AddToHomeScreenButton = ({ children, onClose, onInstall }) => {
  useEffect(() => {
    document.body.classList.add(classes.a2hs);
    return () => document.body.classList.remove(classes.a2hs);
  }, []);

  return (
    <div className={classes.wrapper} onClick={onInstall}>
      <Container>
        <div className={classes.content}>
          <button className={classes.closeBtn} onClick={onClose}>
            &#10005;
          </button>
          <img src={url} className={classes.logo} alt="logo" />
          <span className={classes.text}>{children}</span>
        </div>
      </Container>
    </div>
  );
};

export default AddToHomeScreenButton;
