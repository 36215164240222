export function updateManifest(token) {
  const { origin } = window.location;
  const manifest = {
    name: 'LemonOne',
    short_name: 'LemonOne',
    start_url: `${origin}/offers#${token}`,
    description: 'Earn extra money with exciting photoshoots!',
    display: 'standalone',
    icons: [
      {
        src: `${origin}/manifest-icon-192.png`,
        sizes: '192x192',
        type: 'image/png',
        purpose: 'maskable any'
      },
      {
        src: `${origin}/manifest-icon-512.png`,
        sizes: '512x512',
        type: 'image/png',
        purpose: 'maskable any'
      }
    ],
    theme_color: '#205152',
    background_color: '#FFFFFF'
  };

  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  const el = document.getElementById('manifest');
  el.setAttribute('href', manifestURL);
}
