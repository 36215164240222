/* eslint-disable quotes */

const intl = require('@lemonone/intl/lib/en');
const components = require('@lemonone/components/lib/i18n/en');

const unauthorized =
  '⚠️ Your session is expired.\n\nYou can login by clicking "Want to earn more?" banner from offer emails.\n\nIf the issue persists, please reach out to our support team.';

module.exports = Object.assign({}, intl, components, {
  '@lemonone/acceptphotoshoot/pages:photoshoot': 'Photoshoot',
  '@lemonone/acceptphotoshoot/text:accept': 'Accept Photoshoot',
  '@lemonone/acceptphotoshoot/text:terms':
    'By clicking on "Accept photoshoot" you agree to our <a>terms & conditions</a>.',
  '@lemonone/acceptphotoshoot/text:terms:href': 'https://www.lemonone.com/en/terms-conditions',
  '@lemonone/acceptphotoshoot/text:availability':
    'If you are available and want to take this photoshoot, please confirm with the button below:',
  '@lemonone/acceptphotoshoot/text:booked': 'You have been booked for this photoshoot',
  '@lemonone/acceptphotoshoot/text:confirmed':
    'The confirmation sent to your email and appointment added to your calendar',
  '@lemonone/acceptphotoshoot/text:unavailable': 'Unfortunately, the selected photoshoot has already been taken',
  '@lemonone/acceptphotoshoot/text:address': 'Address',
  '@lemonone/acceptphotoshoot/text:booked_date': 'Photoshoot date',
  '@lemonone/acceptphotoshoot/text:categories': 'Photo Categories',
  '@lemonone/acceptphotoshoot/text:actual_duration': 'Photoshoot duration',
  '@lemonone/acceptphotoshoot/text:earnings': '{earnings} {currency}',
  '@lemonone/acceptphotoshoot/text:travel_cost_included':
    'including travel cost compensation, according to §2 of your contract, otherwise §13 of the AGB',
  '@lemonone/acceptphotoshoot/text:travel_cost_not_included':
    '+ travel cost compensation, according to §2 of your contract, otherwise §13 of the AGB',
  '@lemonone/acceptphotoshoot/text:you_will_earn': 'You will earn',
  '@lemonone/acceptphotoshoot/text:photoshoot_id': 'Photoshoot identifier',
  '@lemonone/acceptphotoshoot/text:travel_estimation': '{travelDistance} km, {travelDuration} mins from your address',
  '@lemonone/acceptphotoshoot/text:equipment': 'Required equipment:',
  '@lemonone/acceptphotoshoot/text:detail_lens': 'Detail / portrait lens',
  '@lemonone/acceptphotoshoot/text:wide_lens': 'Wide angle lens (16 - 20mm fullframe or equiv.)',
  '@lemonone/acceptphotoshoot/text:drone': 'Professional quality UAV',
  '@lemonone/acceptphotoshoot/text:drone_license': 'UAV operator certificate/license (required)',
  '@lemonone/acceptphotoshoot/text:tripod': 'Tripod with an extension arm',
  '@lemonone/acceptphotoshoot/text:tilt_lens': 'Tilt-shift lens',
  '@lemonone/acceptphotoshoot/text:360_camera': '360 camera',
  '@lemonone/acceptphotoshoot/text:today_date': 'Today, {timeNow, date, long}',
  '@lemonone/acceptphotoshoot/text:tomorrow_date': 'Tomorrow, {timeTomorrow, date, long}',
  '@lemonone/acceptphotoshoot/text:upcoming_days': 'In the upcoming days',
  '@lemonone/acceptphotoshoot/text:all_offers': 'All your offers',
  '@lemonone/acceptphotoshoot/text:check_shoots': 'See other shoots close by <underline>here</underline>',
  '@lemonone/acceptphotoshoot/text:earn_more': 'Want to earn more?',
  '@lemonone/acceptphotoshoot/text:no_offers':
    'Unfortunately, there are no offers available at the moment. Please come back later!',
  '@lemonone/acceptphotoshoot/error:GraphQL error: jwt must be provided': unauthorized,
  '@lemonone/acceptphotoshoot/error:GraphQL error: jwt malformed': unauthorized,
  '@lemonone/acceptphotoshoot/error:GraphQL error: invalid signature': unauthorized,
  '@lemonone/acceptphotoshoot/error:GraphQL error: invalid token': unauthorized,
  '@lemonone/acceptphotoshoot/text:date': 'Date',
  '@lemonone/acceptphotoshoot/text:address_and_type': 'Address & type',
  '@lemonone/acceptphotoshoot/text:price': 'Price',
  '@lemonone/acceptphotoshoot/text:status': 'Status',
  '@lemonone/acceptphotoshoot/error:GraphQL error: No order or shoot given!':
    'There is not photoshoot booking for the identifier you provided',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Photographer cannot shoot this customer!':
    'Photographer cannot shoot this customer!',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Photographer inactive!': 'The Photographer is inactive!',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Photographer is missing tag!':
    "You can't take this shoot. Equipment required for the shoot is missing.",
  '@lemonone/acceptphotoshoot/error:GraphQL error: Photographer missing category!':
    "You can't take this shoot. Category required for the shoot is missing.",
  '@lemonone/acceptphotoshoot/error:GraphQL error: Photographer missing!':
    "You can't take this shoot. We are missing your photographer identifier.",
  '@lemonone/acceptphotoshoot/error:GraphQL error: Product missing!': 'The order is missing the product.',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Shoot lies in the past': 'The offered shoot already took place.',
  '@lemonone/acceptphotoshoot/error:GraphQL error: Variable "$targetPhotographerUuid" of required type "String!" was not provided.':
    "You can't take this shoot. We are missing your photographer identifier.",
  "@lemonone/acceptphotoshoot/error:GraphQL error: Variable 'orderUuid' has coerced Null value for NonNull type 'String!'":
    'You need to provide the photoshoot booking identifier',
  '@lemonone/acceptphotoshoot/text:install_app': 'Tap here to add LemonOne app to your home screen',
  '@lemonone/acceptphotoshoot/text:check_offer': 'Check offer',
  '@lemonone/acceptphotoshoot/text:offer_status/ACCEPTED': 'Accepted',
  '@lemonone/acceptphotoshoot/text:offer_status/CANCELLED': 'Cancelled',
  '@lemonone/acceptphotoshoot/text:offer_status/AVAILABLE': 'Available'
});
