/* eslint-disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Query = {
  __typename?: 'Query';
  getProfile: Profile;
  getAvailableShootOrder?: Maybe<LeanOrder>;
  getShootOffer?: Maybe<GetShootOfferResult>;
  getPhotographerShoots: Array<GetShootOfferResult>;
};

export type QueryGetAvailableShootOrderArgs = {
  shootUuid: Scalars['String'];
  targetPhotographerUuid?: InputMaybe<Scalars['String']>;
  sourcePhotographerUuid?: InputMaybe<Scalars['String']>;
  skipCollisionCheck?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetShootOfferArgs = {
  shootOfferUuid: Scalars['String'];
};

export type Profile = AnonymousProfile | PhotographerProfile;

export type AnonymousProfile = {
  __typename?: 'AnonymousProfile';
  language: Scalars['String'];
};

export type PhotographerProfile = {
  __typename?: 'PhotographerProfile';
  language: Scalars['String'];
};

export type LeanOrder = {
  __typename?: 'LeanOrder';
  createdAt?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  shoot?: Maybe<Shoot>;
  status: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

export type Product = {
  __typename?: 'Product';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  equipment: Array<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  price?: Maybe<Money>;
  duration?: Maybe<Scalars['Float']>;
  needsInfo?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  uuid?: Maybe<Scalars['ID']>;
  skills: Array<Scalars['String']>;
  photosMin: Scalars['Int'];
  internalWalkVideo?: Maybe<Scalars['Boolean']>;
  proWalkVideo?: Maybe<Scalars['Boolean']>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Int'];
  currency: Scalars['String'];
};

export type Shoot = {
  __typename?: 'Shoot';
  end: Scalars['String'];
  location: Location;
  googleCalendarId?: Maybe<Scalars['String']>;
  googleEventId?: Maybe<Scalars['String']>;
  orderUuid: Scalars['String'];
  start: Scalars['String'];
  status: Scalars['String'];
  uuid: Scalars['ID'];
};

export type Location = {
  __typename?: 'Location';
  address: Scalars['String'];
  geometry?: Maybe<Geometry>;
  name?: Maybe<Scalars['String']>;
  timeZoneId?: Maybe<Scalars['String']>;
};

export type Geometry = {
  __typename?: 'Geometry';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  type?: Maybe<Scalars['String']>;
};

export type GetShootOfferResult = {
  __typename?: 'GetShootOfferResult';
  order: LeanOrder;
  shootOffer: ShootOffer;
};

export type ShootOffer = {
  __typename?: 'ShootOffer';
  uuid: Scalars['ID'];
  cost: Scalars['Float'];
  currency: Scalars['String'];
  travelDuration: Scalars['Int'];
  travelDistance: Scalars['Float'];
  travelCostIncluded: Scalars['Boolean'];
  status: OfferStatus;
  photographerUuid: Scalars['String'];
};

export enum OfferStatus {
  Accepted = 'ACCEPTED',
  Available = 'AVAILABLE',
  Cancelled = 'CANCELLED'
}

export type Mutation = {
  __typename?: 'Mutation';
  switchShootPhotographer: Shoot;
};

export type MutationSwitchShootPhotographerArgs = {
  shootUuid: Scalars['String'];
  targetPhotographerUuid: Scalars['String'];
  sourcePhotographerUuid?: InputMaybe<Scalars['String']>;
  shootOfferUuid?: InputMaybe<Scalars['String']>;
  skipCollisionCheck?: InputMaybe<Scalars['Boolean']>;
};

export type LocationInput = {
  address: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type ShootInput = {
  end: Scalars['String'];
  location: LocationInput;
  calendarId: Scalars['String'];
  start: Scalars['String'];
  timeZoneId?: InputMaybe<Scalars['String']>;
};

export type GetOrderQueryVariables = Exact<{
  shootUuid: Scalars['String'];
  sourcePhotographerUuid?: Maybe<Scalars['String']>;
  targetPhotographerUuid?: Maybe<Scalars['String']>;
  skipCollisionCheck?: Maybe<Scalars['Boolean']>;
}>;

export type GetOrderQuery = {
  __typename?: 'Query';
  order?: Maybe<{
    __typename?: 'LeanOrder';
    product?: Maybe<{
      __typename?: 'Product';
      duration?: Maybe<number>;
      skills: Array<string>;
      equipment: Array<string>;
      photosMin: number;
      internalWalkVideo?: Maybe<boolean>;
      proWalkVideo?: Maybe<boolean>;
    }>;
    shoot?: Maybe<{
      __typename?: 'Shoot';
      uuid: string;
      start: string;
      location: { __typename?: 'Location'; name?: Maybe<string>; address: string; timeZoneId?: Maybe<string> };
    }>;
  }>;
};

export type GetPhotographerShootsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPhotographerShootsQuery = {
  __typename?: 'Query';
  getPhotographerShoots: Array<{
    __typename?: 'GetShootOfferResult';
    order: {
      __typename?: 'LeanOrder';
      product?: Maybe<{
        __typename?: 'Product';
        duration?: Maybe<number>;
        skills: Array<string>;
        equipment: Array<string>;
        photosMin: number;
        internalWalkVideo?: Maybe<boolean>;
        proWalkVideo?: Maybe<boolean>;
      }>;
      shoot?: Maybe<{
        __typename?: 'Shoot';
        uuid: string;
        start: string;
        location: { __typename?: 'Location'; name?: Maybe<string>; address: string; timeZoneId?: Maybe<string> };
      }>;
    };
    offer: {
      __typename?: 'ShootOffer';
      uuid: string;
      cost: number;
      currency: string;
      travelDuration: number;
      travelDistance: number;
      status: OfferStatus;
      photographerUuid: string;
    };
  }>;
};

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileQuery = {
  __typename?: 'Query';
  getProfile:
    | { __typename?: 'AnonymousProfile'; language: string }
    | { __typename?: 'PhotographerProfile'; language: string };
};

export type GetShootOfferQueryVariables = Exact<{
  shootOfferUuid: Scalars['String'];
}>;

export type GetShootOfferQuery = {
  __typename?: 'Query';
  offer?: Maybe<{
    __typename?: 'GetShootOfferResult';
    order: {
      __typename?: 'LeanOrder';
      product?: Maybe<{
        __typename?: 'Product';
        duration?: Maybe<number>;
        skills: Array<string>;
        equipment: Array<string>;
        photosMin: number;
        internalWalkVideo?: Maybe<boolean>;
        proWalkVideo?: Maybe<boolean>;
      }>;
      shoot?: Maybe<{
        __typename?: 'Shoot';
        uuid: string;
        start: string;
        location: { __typename?: 'Location'; name?: Maybe<string>; address: string; timeZoneId?: Maybe<string> };
      }>;
    };
    offer: {
      __typename?: 'ShootOffer';
      uuid: string;
      cost: number;
      currency: string;
      travelDuration: number;
      travelDistance: number;
      status: OfferStatus;
      photographerUuid: string;
    };
  }>;
};

export type ProductFieldsFragment = {
  __typename?: 'Product';
  duration?: Maybe<number>;
  skills: Array<string>;
  equipment: Array<string>;
  photosMin: number;
  internalWalkVideo?: Maybe<boolean>;
  proWalkVideo?: Maybe<boolean>;
};

export type SwitchShootPhotographerMutationVariables = Exact<{
  shootUuid: Scalars['String'];
  sourcePhotographerUuid?: Maybe<Scalars['String']>;
  targetPhotographerUuid: Scalars['String'];
  shootOfferUuid?: Maybe<Scalars['String']>;
  skipCollisionCheck?: Maybe<Scalars['Boolean']>;
}>;

export type SwitchShootPhotographerMutation = {
  __typename?: 'Mutation';
  switchShootPhotographer: { __typename?: 'Shoot'; uuid: string };
};

export const ProductFieldsFragmentDoc = gql`
  fragment productFields on Product {
    duration
    skills
    equipment
    photosMin
    internalWalkVideo
    proWalkVideo
  }
`;
export const GetOrderDocument = gql`
  query getOrder(
    $shootUuid: String!
    $sourcePhotographerUuid: String
    $targetPhotographerUuid: String
    $skipCollisionCheck: Boolean
  ) {
    order: getAvailableShootOrder(
      shootUuid: $shootUuid
      sourcePhotographerUuid: $sourcePhotographerUuid
      targetPhotographerUuid: $targetPhotographerUuid
      skipCollisionCheck: $skipCollisionCheck
    ) {
      product {
        ...productFields
      }
      shoot {
        uuid
        start
        location {
          name
          address
          timeZoneId
        }
      }
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      shootUuid: // value for 'shootUuid'
 *      sourcePhotographerUuid: // value for 'sourcePhotographerUuid'
 *      targetPhotographerUuid: // value for 'targetPhotographerUuid'
 *      skipCollisionCheck: // value for 'skipCollisionCheck'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
}
export function useGetOrderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = ApolloReactCommon.QueryResult<GetOrderQuery, GetOrderQueryVariables>;
export const GetPhotographerShootsDocument = gql`
  query getPhotographerShoots {
    getPhotographerShoots {
      order {
        product {
          ...productFields
        }
        shoot {
          uuid
          start
          location {
            name
            address
            timeZoneId
          }
        }
      }
      offer: shootOffer {
        uuid
        cost
        currency
        travelDuration
        travelDistance
        status
        photographerUuid
      }
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useGetPhotographerShootsQuery__
 *
 * To run a query within a React component, call `useGetPhotographerShootsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhotographerShootsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhotographerShootsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPhotographerShootsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPhotographerShootsQuery, GetPhotographerShootsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPhotographerShootsQuery, GetPhotographerShootsQueryVariables>(
    GetPhotographerShootsDocument,
    options
  );
}
export function useGetPhotographerShootsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPhotographerShootsQuery, GetPhotographerShootsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPhotographerShootsQuery, GetPhotographerShootsQueryVariables>(
    GetPhotographerShootsDocument,
    options
  );
}
export type GetPhotographerShootsQueryHookResult = ReturnType<typeof useGetPhotographerShootsQuery>;
export type GetPhotographerShootsLazyQueryHookResult = ReturnType<typeof useGetPhotographerShootsLazyQuery>;
export type GetPhotographerShootsQueryResult = ApolloReactCommon.QueryResult<
  GetPhotographerShootsQuery,
  GetPhotographerShootsQueryVariables
>;
export const GetProfileDocument = gql`
  query GetProfile {
    getProfile {
      ... on AnonymousProfile {
        language
      }
      ... on PhotographerProfile {
        language
      }
    }
  }
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
}
export function useGetProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = ApolloReactCommon.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const GetShootOfferDocument = gql`
  query getShootOffer($shootOfferUuid: String!) {
    offer: getShootOffer(shootOfferUuid: $shootOfferUuid) {
      order {
        product {
          ...productFields
        }
        shoot {
          uuid
          start
          location {
            name
            address
            timeZoneId
          }
        }
      }
      offer: shootOffer {
        uuid
        cost
        currency
        travelDuration
        travelDistance
        status
        photographerUuid
      }
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useGetShootOfferQuery__
 *
 * To run a query within a React component, call `useGetShootOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShootOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShootOfferQuery({
 *   variables: {
 *      shootOfferUuid: // value for 'shootOfferUuid'
 *   },
 * });
 */
export function useGetShootOfferQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetShootOfferQuery, GetShootOfferQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetShootOfferQuery, GetShootOfferQueryVariables>(GetShootOfferDocument, options);
}
export function useGetShootOfferLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetShootOfferQuery, GetShootOfferQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetShootOfferQuery, GetShootOfferQueryVariables>(GetShootOfferDocument, options);
}
export type GetShootOfferQueryHookResult = ReturnType<typeof useGetShootOfferQuery>;
export type GetShootOfferLazyQueryHookResult = ReturnType<typeof useGetShootOfferLazyQuery>;
export type GetShootOfferQueryResult = ApolloReactCommon.QueryResult<GetShootOfferQuery, GetShootOfferQueryVariables>;
export const SwitchShootPhotographerDocument = gql`
  mutation switchShootPhotographer(
    $shootUuid: String!
    $sourcePhotographerUuid: String
    $targetPhotographerUuid: String!
    $shootOfferUuid: String
    $skipCollisionCheck: Boolean
  ) {
    switchShootPhotographer(
      shootUuid: $shootUuid
      sourcePhotographerUuid: $sourcePhotographerUuid
      targetPhotographerUuid: $targetPhotographerUuid
      shootOfferUuid: $shootOfferUuid
      skipCollisionCheck: $skipCollisionCheck
    ) {
      uuid
    }
  }
`;
export type SwitchShootPhotographerMutationFn = ApolloReactCommon.MutationFunction<
  SwitchShootPhotographerMutation,
  SwitchShootPhotographerMutationVariables
>;

/**
 * __useSwitchShootPhotographerMutation__
 *
 * To run a mutation, you first call `useSwitchShootPhotographerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchShootPhotographerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchShootPhotographerMutation, { data, loading, error }] = useSwitchShootPhotographerMutation({
 *   variables: {
 *      shootUuid: // value for 'shootUuid'
 *      sourcePhotographerUuid: // value for 'sourcePhotographerUuid'
 *      targetPhotographerUuid: // value for 'targetPhotographerUuid'
 *      shootOfferUuid: // value for 'shootOfferUuid'
 *      skipCollisionCheck: // value for 'skipCollisionCheck'
 *   },
 * });
 */
export function useSwitchShootPhotographerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SwitchShootPhotographerMutation,
    SwitchShootPhotographerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SwitchShootPhotographerMutation, SwitchShootPhotographerMutationVariables>(
    SwitchShootPhotographerDocument,
    options
  );
}
export type SwitchShootPhotographerMutationHookResult = ReturnType<typeof useSwitchShootPhotographerMutation>;
export type SwitchShootPhotographerMutationResult = ApolloReactCommon.MutationResult<SwitchShootPhotographerMutation>;
export type SwitchShootPhotographerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SwitchShootPhotographerMutation,
  SwitchShootPhotographerMutationVariables
>;
