import { useState, useCallback } from 'react';
import { LS_TOKEN_KEY } from './constants';
import { decodeSession } from './decodeSession';

export const useSession = () => {
  const [session, setSession] = useState(() => {
    const token = localStorage.getItem(LS_TOKEN_KEY);
    if (!token) return null;
    return decodeSession(token);
  });

  const createSession = useCallback(
    newToken => {
      const payload = decodeSession(newToken);
      localStorage.setItem(LS_TOKEN_KEY, newToken);
      setSession(payload);
    },
    [setSession]
  );

  const destroySession = useCallback(() => {
    localStorage.removeItem(LS_TOKEN_KEY);
    setSession(null);
  }, [setSession]);

  return {
    session,
    createSession,
    destroySession
  };
};
