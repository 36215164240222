import React from 'react';
import classes from './OffersTableGroup.module.css';

export const OffersTableGroup = ({ children, text }) => {
  return (
    <>
      <tr className={classes.headerRow}>
        <td colSpan={4} className={classes.headerCell}>
          {text}
        </td>
      </tr>
      {children}
    </>
  );
};
