import React, { useEffect } from 'react';
import queryString from 'query-string';
import { identify } from 'utils/analytics';
import { initLogger, setExtra } from 'utils/logger';
import { useLocation } from 'react-router-dom';
import AcceptPhotoShootPage from './AcceptPhotoShootPage';

const AppAcceptPhotoShoot = () => {
  const { search } = useLocation();
  const {
    sid: shootUuid,
    spid: sourcePhotographerUuid,
    tpid: targetPhotographerUuid,
    offerid: shootOfferUuid,
    skipCollisionCheck
  } = queryString.parse(search);

  useEffect(() => {
    setExtra('shootUuid', shootUuid);
    setExtra('sourcePhotographerUuid', sourcePhotographerUuid);
    setExtra('targetPhotographerUuid', targetPhotographerUuid);
    setExtra('shootOfferUuid', shootOfferUuid);

    initLogger({
      shootUuid,
      sourcePhotographerUuid,
      targetPhotographerUuid
    });

    // identify request
    identify({
      shootUuid,
      sourcePhotographerUuid,
      targetPhotographerUuid
    });
  }, [shootUuid, sourcePhotographerUuid, targetPhotographerUuid, shootOfferUuid]);

  return (
    <AcceptPhotoShootPage
      shootUuid={shootUuid}
      sourcePhotographerUuid={sourcePhotographerUuid}
      targetPhotographerUuid={targetPhotographerUuid}
      shootOfferUuid={shootOfferUuid}
      skipCollisionCheck={skipCollisionCheck === 'true'}
    />
  );
};

export default AppAcceptPhotoShoot;
