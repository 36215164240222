import React, { createContext, useContext } from 'react';

import { useSession as useLocalStorageSession } from '../utils/useSession';

const SessionContext = createContext(null);

const { Provider } = SessionContext;

export const SessionProvider = ({ children }) => {
  const session = useLocalStorageSession();
  return <Provider value={session}>{children}</Provider>;
};

export const useSession = () => {
  return useContext(SessionContext);
};
